/* Archivo */
@font-face {
  font-family: "Archivo";
  src: local(""), url("/fonts/Archivo/archivo-100.woff2") format("woff2"),
    url("/fonts/Archivo/archivo-100.woff") format("woff");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: local(""), url("/fonts/Archivo/archivo-200.woff2") format("woff2"),
    url("/fonts/Archivo/archivo-200.woff") format("woff");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: local(""), url("/fonts/Archivo/archivo-300.woff2") format("woff2"),
    url("/fonts/Archivo/archivo-300.woff") format("woff");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: local(""), url("/fonts/Archivo/archivo-regular.woff2") format("woff2"),
    url("/fonts/Archivo/archivo-regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: local(""), url("/fonts/Archivo/archivo-medium.woff2") format("woff2"),
    url("/fonts/Archivo/archivo-medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: local(""), url("/fonts/Archivo/archivo-semibold.woff2") format("woff2"),
    url("/fonts/Archivo/archivo-semibold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: local(""), url("/fonts/Archivo/archivo-bold.woff2") format("woff2"),
    url("/fonts/Archivo/archivo-bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: local(""), url("/fonts/Archivo/archivo-extrabold.woff2") format("woff2"),
    url("/fonts/Archivo/archivo-extrabold.woff") format("woff");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: local(""), url("/fonts/Archivo/archivo-black.woff2") format("woff2"),
    url("/fonts/Archivo/archivo-black.woff") format("woff");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

/* Archivo Narrow */
@font-face {
  font-family: "Archivo Narrow";
  src: local(""), url("/fonts/ArchivoNarrow/archivo-narrow-regular.woff2") format("woff2"),
    url("/fonts/ArchivoNarrow/archivo-narrow-regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Narrow";
  src: local(""), url("/fonts/ArchivoNarrow/archivo-narrow-medium.woff2") format("woff2"),
    url("/fonts/ArchivoNarrow/archivo-narrow-medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Narrow";
  src: local(""), url("/fonts/ArchivoNarrow/archivo-narrow-semibold.woff2") format("woff2"),
    url("/fonts/ArchivoNarrow/archivo-narrow-semibold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Narrow";
  src: local(""), url("/fonts/ArchivoNarrow/archivo-narrow-bold.woff2") format("woff2"),
    url("/fonts/ArchivoNarrow/archivo-narrow-bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
