@import "font";

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

$sidebar-bg-color: #060f17;
$icon-size: 20px;
$sidebar-collapsed-width: 79px;
$sidebar-width: 100%;
$sidebar-color: #e5e5e5;
// $highlight-color: #E5E5E5;
// $sidebar-bg-color: #1d1d1d !default;
// $sidebar-color: #adadad !default;
// $sidebar-width: 270px !default;
// $sidebar-collapsed-width: 80px !default;
// $highlight-color: #d8d8d8 !default;
// $submenu-bg-color: #2b2b2b !default;
// $submenu-bg-color-collapsed: #2b2b2b !default;
// $icon-bg-color: #2b2b2b !default;
// $icon-size: 35px !default;
// $submenu-indent: 24px !default;
// $breakpoint-xs: 480px !default;
// $breakpoint-sm: 576px !default;
// $breakpoint-md: 768px !default;
// $breakpoint-lg: 992px !default;
// $breakpoint-xl: 1200px !default;
// $breakpoint-xxl: 1600px !default;
@import "~react-pro-sidebar/dist/scss/styles.scss"; // for react-pro-sidebar
@import "react-date-range/dist/styles.css"; // main style file for react-date-picker
@import "react-date-range/dist/theme/default.css"; // theme css file for react-date-picker

* {
  letter-spacing: 1px;
}

body {
  font-family: "Archivo";
  overflow-x: hidden;
}

// for floating label
.floatiglabel {
  input:focus-within ~ label,
  input:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-4 text-[12px] top-[7px] bg-white;
  }

  textarea:focus-within ~ label,
  textarea:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-5 text-[12px] top-[10px] bg-white;
  }
}

.css-1pahdxg-control {
  border-color: #c2c2c2 !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border-color: #50c878 !important;
  @apply ring-0;
}

.css-14el2xx-placeholder,
.css-qc6sy-singleValue {
  @apply sm:text-xs lg:text-body1 xl:text-base;
}

.css-26l3qy-menu {
  background-color: white !important;
  z-index: 2 !important;
}

.css-1n7v3ny-option {
  background-color: #50c878 !important;
  color: white !important;
}

.css-9gakcf-option {
  background-color: #50c878 !important;
}

.css-319lph-ValueContainer {
  padding: 0.5rem 8px !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 10px 8px !important;
  justify-content: center;
}

.pro-sidebar .pro-menu {
  padding: 0;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 5px;
}

.pro-sidebar-layout {
  transition: all 0.2s ease;
  -ms-overflow-style: none; // to removce scroll in IE and Edge
  scrollbar-width: none; //  to removce scroll in Firefox
}

//to remove scroll in sidebar
.pro-sidebar-layout::-webkit-scrollbar {
  display: none;
}

.css-4ljt47-MenuList {
  @apply flex flex-col gap-[3px];
}

.css-tlfecz-indicatorContainer {
  padding: 5px !important;
}

.rdrDateDisplay,
.rdrStartEdge,
.rdrInRange,
.rdrEndEdge {
  color: #50c878 !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview {
  @apply focus-visible:outline-none outline-offset-0 border-0;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #50c878;
}

.rdrDayInPreview {
  color: #50c878 !important;
}

.css-319lph-ValueContainer {
  @apply capitalize;
}

select:focus {
  --tw-ring-color: #50c878;
}

.table_link {
  @apply text-primary-main cursor-pointer border-b-0.5 border-primary-main pb-1;
}

.component_filter_list_details {
  @apply w-full flex justify-between items-center px-6 py-5 gap-5 bg-white shadow-sm;
}

.component_filter_badges {
  @apply h-full flex items-center;
}

.component_filter_title {
  @apply text-body1 pr-2 border-r-1 text-font-textcolor1;
}

.component_separates {
  @apply flex flex-row flex-wrap gap-4 pl-4;
}

.component_badge {
  @apply bg-[#F3F4F6] text-font-textcolor1 rounded-full p-1 px-3 text-body1 font-medium cursor-default;
}

.component_clearall {
  @apply pl-2 text-body1 border-l-1 cursor-pointer;
}

.component_error_toast {
  @apply bg-[#FEF2F2] w-[432px] h-[68px] flex justify-start items-center rounded-sm p-3;
}

.component_toast_icon {
  @apply px-8 w-[10%] flex justify-center items-start;
}

.component_toast_error_text {
  @apply text-error-main text-body1 w-[80%];
}

.component_toast_close_text {
  @apply w-[10%] cursor-pointer flex justify-center items-start;
}

.component_success_toast {
  @apply bg-[#ECFDF5] w-[432px] h-[68px] flex justify-start items-center rounded-sm p-3;
}

.component_toast_icon {
  @apply px-8 w-[10%] flex justify-center items-start;
}

.component_toast_success_text {
  @apply text-primary-main text-body1 w-[80%];
}

.component_toast_close_icon {
  @apply w-[10%] cursor-pointer flex justify-center items-start;
}

.ps__rail-y {
  opacity: 0.6 !important;
  left: auto !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus,
.css-1x5jdmq:focus {
  outline: 0;
  --tw-ring-color: transparent;
  border-color: #50c878;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1x5jdmq {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  outline: none !important;
  @apply border-others-iconcolorlight text-others-black;
}

.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input,
.css-igs3ac {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  outline: none !important;
  @apply border-others-iconcolorlight text-others-black;
}

.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-igs3ac.Mui-disabled {
  @apply text-others-black;
  -webkit-text-fill-color: #000000;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
.css-u9osun.Mui-focused,
.css-u9osun {
  outline: none !important;
  font-family: "Archivo" !important;
  letter-spacing: 1px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-p0rm37 {
  outline: 1px solid transparent !important;
  outline-offset: 1px !important;
  top: -6px !important;
  font-size: 14px !important;
  font-family: "Archivo" !important;
  letter-spacing: 1px !important;
  @apply capitalize;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.css-1v4ccyo {
  @apply focus-visible:outline-primary-main;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:focus-visible,
.css-1v4ccyo:focus-visible {
  outline: 1px solid transparent;
  outline-offset: 1px;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-u9osun.Mui-focused,
.css-1ald77x.Mui-focused {
  color: #9b9b9b !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  border-color: #50c878;
  border-width: 1px !important;
  outline: none;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  outline: none !important;
  --tw-ring-color: transparent;
  border-color: #50c878 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
  outline: none;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: none !important;
  outline-offset: 0px;
  --tw-ring-color: transparent;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.css-i44wyl {
  @apply w-full;
  outline: none;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-1x5jdmq.Mui-disabled {
  @apply text-others-black;
  -webkit-text-fill-color: #000000;
}

// input[type=time]::-webkit-datetime-edit {
//   color: transparent;
// }

.css-1v4ccyo.Mui-focused:focus .MuiOutlinedInput-notchedOutline:focus,
.css-igs3ac:focus {
  border-color: #50c878;
}

.css-1v4ccyo:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input:focus,
.css-u36398:focus {
  --tw-ring-color: none;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  outline: none !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-1hof3tc.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-qiwgdb {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  font-family: "Archivo" !important;
  color: #333333 !important;
  @apply border-others-iconcolorlight;
  letter-spacing: 1px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.css-1km1ehz {
  font-family: "Archivo" !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  margin-bottom: 0.25rem !important;
  letter-spacing: 1px !important;

  &:last-child {
    margin-bottom: 0px !important;
  }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-1km1ehz.Mui-selected {
  background-color: #50c878 !important;
  color: #ffffff !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover,
.css-1km1ehz:hover {
  background-color: #50c878 !important;
  color: #ffffff !important;
}

.dashboard_cal_icon {
  @apply bg-white rounded-lg py-[6px] px-2 cursor-pointer shadow;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  border-color: #50c878;
  border-width: 1px !important;
  outline: none;
  --tw-ring-color: none !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  @apply focus-visible:outline-none;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  outline: none !important;
  --tw-ring-color: transparent;
  border-color: #50c878 !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
  outline: none;
}

.css-1uvydh2 {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  outline: none !important;
  --tw-ring-color: transparent;
}

.css-1uvydh2:focus {
  --tw-ring-color: transparent;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input:focus {
  --tw-ring-color: transparent;
}

.css-1v4ccyo:hover .MuiOutlinedInput-notchedOutline,
.css-1ufn0jl.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-1ufn0jl:hover .MuiOutlinedInput-notchedOutline,
.css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input,
.css-1ixds2g {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  outline: none !important;
  --tw-ring-color: transparent;
  border-color: #50c878 !important;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input:focus,
.css-1ixds2g:focus {
  --tw-ring-color: transparent;
}

.css-fvipm8:hover .MuiOutlinedInput-notchedOutline,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

// css for autocompleted
.css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input,
.css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0px 0px 0px 0px !important;
  width: 100% !important;
  font-size: 14px !important;
  font-family: Archivo !important;
  color: #333 !important;
  letter-spacing: 1px !important;
  text-transform: capitalize;
  --tw-ring-color: none !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-segi59.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-segi59:hover .MuiOutlinedInput-notchedOutline,
.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-1si533m:hover .MuiOutlinedInput-notchedOutline,
.css-1si533m.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1q79v3g-MuiButtonBase-root-MuiChip-root {
  background-color: #f3f4f6 !important;
}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused,
.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"],
.css-ue1yok .MuiAutocomplete-option[aria-selected="true"],
.css-ue1yok .MuiAutocomplete-option.Mui-focused {
  background-color: #50c878 !important;
  color: #fff !important;
}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option,
.css-ue1yok .MuiAutocomplete-option {
  font-family: Archivo !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  margin-bottom: 0.25rem !important;
  letter-spacing: 1px !important;
}

.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input:focus,
.css-1gnht4k:focus {
  --tw-ring-color: none !important;
  outline-offset: 0 !important;
  border-color: unset !important;
}

.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-x0qxq3:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-x0qxq3.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

// css for filter modal
.filtermodal_container {
  @apply flex flex-col justify-start items-center w-full p-10 gap-7 overflow-y-auto;

  .filtermodal_inputs {
    @apply w-full flex flex-col justify-between gap-7;
  }

  .filtermodal_btns {
    @apply flex gap-2 mt-4;
  }

  .filtermodel_separate {
    @apply flex flex-col gap-4 w-full;
  }

  .filtermodal_titles {
    @apply text-[20px] font-medium text-font-textcolor1 leading-7 mb-0;
  }

  .filtermodal_subtitles {
    @apply text-smalltext font-normal text-font-textcolor2 leading-6;
  }
}

#loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: white;
  background-image: url("/image/loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.listbox_dropdown {
  @apply absolute w-full top-[5px] left-0 z-20 py-1 mt-9 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
}

.listbox_gaps {
  @apply flex items-center gap-x-2;
}

.listbox_buttons {
  @apply relative w-[30%] py-2 pl-3 pr-0 text-left bg-white cursor-default focus:border-primary-main focus-visible:outline-none text-body1;
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.spinner {
  width: 60px;
  height: 60px;
  border: 8px solid;
  border-color: #50c878 transparent #50c878 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;

  @media screen and (max-width: 1023px) {
    width: 50px;
    height: 50px;
    border: 6px solid;
    border-color: #50c878 transparent #50c878 transparent;
  }
}

.data-container {
  width: 87%;
  // height: 10%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  // background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.data-spinner {
  width: 60px;
  height: 60px;
  border: 8px solid;
  border-color: #50c878 transparent #50c878 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;

  @media screen and (max-width: 1023px) {
    width: 50px;
    height: 50px;
    border: 6px solid;
    border-color: #50c878 transparent #50c878 transparent;
  }
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #ecfdf5 !important;
  color: #065f46 !important;
  font-weight: 500;
}

.Toastify__toast-body {
  align-items: flex-start;
}

.Toastify__toast-icon {
  align-items: flex-start;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  .Toastify__toast-body {
    .Toastify__toast-icon {
      color: #50c878 !important;
    }
  }

  .Toastify__close-button {
    color: #50c878 !important;
  }
}

.Toastify__toast-container {
  @apply w-[360px] font-sans items-center text-body1;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #fef2f2 !important;
  color: #991b1b !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  .Toastify__toast-body {
    .Toastify__toast-icon {
      color: #f87171 !important;
    }
  }

  .Toastify__close-button {
    color: #991b1b !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #fffbeb !important;
  color: #b45309 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  .Toastify__toast-body {
    .Toastify__toast-icon {
      color: #fbbf24 !important;
    }
  }

  .Toastify__close-button {
    color: #92400e !important;
  }
}

.searchingicon {
  @apply absolute inset-y-0 left-3 flex items-center text-[#C2C2C2];
}

.searchingbox {
  @apply bg-primary-main absolute text-white inset-y-0 right-0 p-3 flex items-center cursor-pointer rounded-r;
}

.input_searchbox {
  width: 321px !important;
  background: white !important;
  --tw-ring-color: none !important;

  .css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root,
  .css-x0qxq3 {
    padding-left: 0px !important;
    border-color: unset !important;
    --tw-ring-color: none !important;
  }

  input {
    @apply bg-white;
    padding-top: 9.5px !important;
    padding-bottom: 9.5px !important;
    padding-left: 35px !important;
    font-size: 14px !important;
    font-family: Archivo !important;
    letter-spacing: 1px !important;
    outline: none !important;
  }

  @media screen and (max-width: 1023px) {
    width: 270px !important;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.table.sticky [data-sticky-first-right-td] {
  box-shadow: -2px 0px 3px #ccc;
}

// delete modal css
.deletemodel_container {
  @apply flex flex-col justify-center items-center w-full py-10;
}

.deletemodel_title {
  @apply w-[80%] text-center text-2xl xl:text-h6 font-semibold mb-5 font-display;
}

.deletemodel_lable {
  @apply w-[80%] text-mediumtext text-font-textcolor1 font-normal mb-0 text-center;
}

.deletemodel_btn {
  @apply mt-10 flex gap-6;
}

.cancel_btn {
  background: #e5e5e5 !important;
  border: 2px solid #e5e5e5 !important;
  color: #000000 !important;
}

.newStatus {
  border-radius: 15px !important;

  .css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .css-feqhe6 {
    background-color: #d1fae5 !important;
    border-radius: 15px !important;

    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
    .css-segi59 {
      border-radius: 15px !important;

      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
      .css-xxees4 .MuiAutocomplete-input,
      .css-1uvydh2 {
        color: #065f46 !important;
        font-weight: 600 !important;
      }
    }
  }
}

.progressStatus {
  border-radius: 15px !important;

  .css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .css-feqhe6 {
    background-color: #fef3c7 !important;
    border-radius: 15px !important;

    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
    .css-segi59 {
      border-radius: 15px !important;

      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
      .css-xxees4 .MuiAutocomplete-input,
      .css-1uvydh2 {
        color: #f59e0b !important;
        font-weight: 600 !important;
      }
    }
  }
}

.holdStatus {
  border-radius: 15px !important;

  .css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .css-feqhe6 {
    background-color: #fee2e2 !important;
    border-radius: 15px !important;

    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
    .css-segi59 {
      border-radius: 15px !important;

      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
      .css-xxees4 .MuiAutocomplete-input,
      .css-1uvydh2 {
        color: #991b1b !important;
        font-weight: 600 !important;
      }
    }
  }
}

.reopenStatus {
  border-radius: 15px !important;

  .css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .css-feqhe6 {
    background-color: #dbeafe !important;
    border-radius: 15px !important;

    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
    .css-segi59 {
      border-radius: 15px !important;

      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
      .css-xxees4 .MuiAutocomplete-input,
      .css-1uvydh2 {
        color: #1e40af !important;
        font-weight: 600 !important;
      }
    }
  }
}

.closeStatus {
  border-radius: 15px !important;

  .css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .css-feqhe6 {
    background-color: #e5e5e5 !important;
    border-radius: 15px !important;

    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
    .css-segi59 {
      border-radius: 15px !important;

      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
      .css-xxees4 .MuiAutocomplete-input,
      .css-1uvydh2 {
        color: #9b9b9b !important;
        font-weight: 600 !important;
      }
    }
  }
}

input::placeholder {
  font-size: 14px;
}

.swiper-pagination {
  text-align: right !important;
  padding-right: 10% !important;
}

.swiper-pagination-bullet {
  width: 60px !important;
  height: 4px !important;
  border-radius: 20px !important;
  background: rgba(255, 255, 255, 0.9) !important;
}

.swiper-pagination-bullet-active {
  background: #ffffff !important;
}

textarea::-webkit-scrollbar {
  width: 6px;
}

textarea::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

textarea::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  background: rgb(175, 174, 174, 0.6);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: #50c878 !important;
}

.rdrDay {
  color: #50c878 !important;
}

.dropdownbox {
  .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
      input {
        padding: 0 !important;
        width: 100% !important;
        font-size: 14px !important;
        font-family: Archivo !important;
        color: #333 !important;
        letter-spacing: 1px !important;
        --tw-ring-color: none !important;
      }
    }
  }
}

.new-table-head-style {
  // background-color: #1E1F25;
  // border-radius: 20px;
  // height: 244px;
  // padding: 0 10px 10px 10px;
  overflow-y: auto;

  Table thead {
    position: sticky;
    top: -20px;
    height: 50px;
    margin: 0 0 0 0;
    z-index: 4;
    background-color: #f7f9fc;
  }
}

.new_table_loadmore {
  @apply absolute bottom-[50px] rounded-lg border-0 left-1/2 z-10;
  transform: translate(-50%, 15%);
}

.tablecard_shadow {
  @apply absolute bottom-0 left-0 right-0 w-full rounded-xl h-[250px] z-10;
  background: linear-gradient(360deg, #f7f9fc 11.36%, rgba(247, 249, 252, 0) 100%);
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}

.spinner-box {
  // width: 300px;
  // height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  .pulse-container {
    // width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pulse-bubble {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #50c878;
  }

  .pulse-bubble-1 {
    animation: pulse 0.4s ease 0s infinite alternate;
  }

  .pulse-bubble-2 {
    animation: pulse 0.4s ease 0.2s infinite alternate;
  }

  .pulse-bubble-3 {
    animation: pulse 0.4s ease 0.4s infinite alternate;
  }
}

.dashboard_card_flip {
  .scene {
    display: inline-block;
    perspective: 600px;
  }

  .card {
    position: relative;
    // width: 100%;
    // height: 100%;
    // cursor: pointer;
    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 1s;
  }

  .card.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }

  .card__face {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // line-height: 260px;
    // color: white;
    // text-align: center;
    // font-weight: bold;
    // font-size: 40px;
    backface-visibility: hidden;
  }

  .card__face--front {
    // background: crimson;
  }

  .card__face--back {
    // background: slateblue;
    transform: rotateY(180deg);
  }
}

.search_box {
  @apply flex justify-center items-center relative h-10 bg-slate-200 rounded-[32px];
}

.search_icon {
  @apply w-[75px] bg-transparent flex justify-center items-center cursor-pointer;
  transition: 0.4s;
  text-decoration: none;

  // &:focus {
  //   @apply mr-[-15px];
  // }
}

.search_text {
  @apply border-none bg-none outline-none w-0 bg-transparent p-0 text-font-textcolor4 bg-slate-200 text-body1 font-normal leading-6;
  transition: 0.4s;

  &:focus {
    @apply border-0 outline-none ring-transparent outline-0 w-[150px] xl:w-[185px];
  }
}

.info_icon {
  @apply w-0 bg-transparent flex items-center justify-center cursor-pointer;
  transition: 0.4s;
}

.search_text:focus ~ .info_icon {
  @apply w-[40px];
}

.search_icon:focus + .search_text {
  @apply w-[150px] xl:w-[185px] bg-transparent;
}

.search_icon:focus ~ .info_icon {
  @apply w-[40px];
}

.search_tooltip {
  @apply w-[170px] border-2 border-solid border-[#EDEFF2] shadow-main;
}

.filter_box {
  @apply flex justify-center items-center h-10 w-[75px] bg-[#F0F2F5] rounded-[32px];
}

/**----------------- rsuite date range picket style --------------------------------*/
.rs-anim-fade.rs-anim-in.rs-picker-popup-daterange.rs-picker-popup {
  margin-top: 10px !important;

  .rs-stack-item {
    .rs-btn-link {
      color: #575757 !important;
    }
  }

  // Change today's cell border color
  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    // -webkit-box-shadow: inset 0 0 0 1px #50c878 !important;
    background-color: rgba(80, 200, 120, 0.1) !important;
    box-shadow: inset 0 0 0 1px #50c878 !important;
  }

  .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    // background-color: rgb(from #50c878 r g b/10%) !important;
    background-color: rgba(80, 200, 120, 0.1) !important;
    color: #50c878 !important;
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #50c878 !important;
  }

  .rs-calendar-table-cell-in-range:before {
    // background-color: rgb(from #50c878 r g b/10%) !important;
    background-color: rgba(80, 200, 120, 0.1) !important;
  }

  .rs-btn-primary {
    background-color: #50c878 !important;
  }

  .rs-picker-daterange-header.rs-picker-tab-active-end:after,
  .rs-picker-daterange-header.rs-picker-tab-active-start:after {
    border-bottom: 2px solid #50c878 !important;
  }

  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: #50c878 !important;
  }

  .rs-calendar-time-dropdown-cell:hover {
    // background-color: rgb(from #50c878 r g b/10%) !important;
    background-color: rgba(80, 200, 120, 0.1) !important;
    color: #50c878 !important;
  }

  .rs-calendar-time-view .rs-calendar-header-title-time {
    color: #50c878 !important;
  }

  .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    background-color: #50c878 !important;
  }

  .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    // background-color: rgb(from #50c878 r g b/10%) !important;
    background-color: rgba(80, 200, 120, 0.1) !important;
    color: #50c878 !important;
  }

  .rs-calendar-month-dropdown-year-active {
    color: #50c878 !important;
    color: var(--rs-text-active);
  }

  .rs-calendar-month-view .rs-calendar-header-title-date {
    color: #50c878 !important;
  }

  .rs-calendar .rs-calendar-time-dropdown-column > ul,
  .rs-calendar-month-dropdown-row-wrapper {
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.rs-picker.rs-picker-daterange.rs-picker-subtle.rs-picker-toggle-wrapper,
.rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper {
  // @apply hidden;
  .rs-input-group:focus-within,
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
  .rs-input-group:not(.rs-input-group-disabled):hover {
    border-color: #50c878 !important;
  }

  .rs-input-group.rs-input-group-inside {
    background-color: transparent !important;
  }

  .rs-input-group:focus-within,
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
    outline: none !important;
  }

  .rs-input:focus {
    --tw-ring-color: transparent;
  }

  [type="text"] {
    @apply hidden;
    padding: 0px !important;
  }

  .rs-input-group-addon {
    padding: 0px !important;
  }

  &.rs-picker-error {
    .rs-input-group {
      border-color: transparent !important;
      // border-color: var(--rs-state-error) !important;
    }
  }
}

.rs-picker.rs-picker-daterange.rs-picker-subtle.rs-picker-toggle-wrapper,
.rs-picker.rs-picker-daterange.rs-picker-subtle.rs-picker-toggle-wrapper {
  .rs-input-group:focus-within,
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
  .rs-input-group:not(.rs-input-group-disabled):hover {
    border-color: transparent !important;
  }
}

/**----------------- rsuite date range picket style end --------------------------------*/

.new-table-scroll-style {
  &::-webkit-scrollbar,
  & > *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;

    &:hover {
      width: 10px;
    }
  }

  &::-webkit-scrollbar-track,
  & > *::-webkit-scrollbar-track {
    display: none;
    opacity: 0;
    background-color: transparent;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    width: 15px;
    right: 0;

    &:hover {
      display: block;
      background-color: #eee;
      opacity: 0.9;
    }
  }

  &::-webkit-scrollbar-thumb,
  & > *::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 6px;
    bottom: 2px;

    &:hover {
      opacity: 0.9;
      width: 15px;
      background-color: #999;
    }
  }
}
